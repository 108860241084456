import React, { useEffect, useState } from "react";
import Form from "../Form/Form";
import Card from "../Card";
import InputCard from "../Form/InputCard";
import Input from "../Form/Input";
import InputTextarea from "../Form/InputTextarea";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import VisitService from "../../services/VisitService";
import InputSelect from "../Form/InputSelect";
import UserService from "../../services/UserService";
import ClientService from "../../services/ClientService";
import { useNotification } from "../../context/errorContext";
import { roleCheck } from "../../services/utils";
import AuthService from "../../services/AuthService";

function VisitForm({ isEdit, id }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const history = useHistory();
  const visitService = new VisitService();
  const userService = new UserService();
  const clientService = new ClientService();
  const authService = new AuthService();
  const { setNotifications, setNotificationVariant, clearNotifications } =
    useNotification();

  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);

  const submitForm = (data) => {
    if (isEdit) {
      visitService
        .update(id, data)
        .then((res) => {
          clearNotifications();
          setNotifications([`Visit updated`]);
          setNotificationVariant("success");
          history.push("/visits");
        })
        .catch((error) => {
          console.error("Something went wrong!", error.response);
          clearNotifications();
          setNotifications(error.response.data.errors);
          setNotificationVariant("danger");
        });
    } else {
      visitService
        .create(data)
        .then((res) => {
          clearNotifications();
          setNotifications([`Visit created`]);
          setNotificationVariant("success");
          history.push("/visits");
        })
        .catch((error) => {
          console.error("Something went wrong!", error.response);
          clearNotifications();
          setNotifications(error.response.data.errors);
          setNotificationVariant("danger");
        });
    }
  };

  const fetchVisit = (id) => {
    visitService
      .getSingle(id)
      .then((res) => {
        let fields = res.data.data;

        setValue("client_id", fields.client_id);
        setValue("volunteer_id", fields.volunteer_id);
        setValue("date_of_visiting", fields.date_of_visiting);
        setValue("length", fields.length);
        setValue("description", fields.description);
        setValue("route", fields.route);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          history.push("/visits");
        }
      });
  };

  const fetchUsers = () => {
    userService
      .getAll({
        custom: "&roles=volunteer&page_type=volunteer",
      })
      .then((res) => {
        const items = res.data.data.map((item) => {
          return {
            value: item.id,
            text: item.full_name,
          };
        });
        setUsers(items);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          history.push("/visits");
        }
      });
  };

  const fetchClients = () => {
    clientService
      .getAll()
      .then((res) => {
        const items = res.data.data.map((item) => {
          return {
            value: item.id,
            text: item.full_name,
          };
        });
        setClients(items);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          history.push("/visits");
        }
      });
  };

  useEffect(() => {
    fetchUsers();
    fetchClients();
  }, []);

  useEffect(() => {
    if (isEdit) {
      fetchVisit(id);
    }
  }, [id, users, clients]);

  const user = authService.getUserData();

  useEffect(() => {
    if (roleCheck(user.roles, ["volunteer"])) {
      setValue("volunteer_id", user.userInfo.id);
    }
  });

  return (
    <div className="container py-10">
      <Form>
        <Card>
          <InputCard>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <InputSelect
                  id="client_id"
                  label="Abivajaja"
                  register={register}
                  required
                  options={clients}
                  error={errors}
                  errorMessage="Abivajaja on vajalik väli"
                />
              </div>
              {roleCheck(user.roles, [
                "super_admin",
                "admin",
                "coordinator",
              ]) && (
                <div>
                  <InputSelect
                    id="volunteer_id"
                    label="Vabatahtlik"
                    register={register}
                    required
                    options={users}
                    error={errors}
                    errorMessage="Vabatahtlik on vajalik väli"
                  />
                </div>
              )}
              <div>
                <Input
                  type="date"
                  id="date_of_visiting"
                  label="Külastuse kuupäev"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Külastuse kuupäev on vajalik väli"
                />
              </div>
              <div>
                <Input
                  type="number"
                  label="Külastuse kestus (tundi)"
                  id="length"
                  min="0"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Külastuse kestus on vajalik väli"
                />
              </div>
              <div className="col-span-2">
                <label>Kirjeldus</label>
                <InputTextarea
                  register={register}
                  required
                  id="description"
                  error={errors}
                  errorMessage="Külastuse kirjeldus on vajalik väli"
                />
              </div>
              <div className="col-span-2">
                <label>Marsruut</label>
                <Input
                  register={register}
                  label="Marsruut"
                  id="route"
                  error={errors}
                  errorMessage=""
                />
              </div>
            </div>
          </InputCard>
        </Card>
        <div className="flex justify-center py-5 space-x-4">
          <button
            className="bg-green-400 py-4 px-6"
            onClick={handleSubmit(submitForm)}
          >
            Salvesta
          </button>
          <button
            onClick={() => history.push("/visits")}
            className="bg-red-400 py-4 px-6"
          >
            Katkesta
          </button>
        </div>
      </Form>
    </div>
  );
}

export default VisitForm;
