import { useState, useEffect, useMemo } from "react";
import Button from "../../components/Button";
import ClientService from "../../services/ClientService";
import CountyService from "../../services/CountyService";
import Table from "../../components/Table";
import { roleCheck } from "../../services/utils";
import AuthService from "../../services/AuthService";

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [counties, setCounties] = useState([]);
  const [countyFilter, setCountyFilter] = useState(null);
  const [filteredClients, setFilteredClients] = useState([]);
  const clientService = new ClientService();
  const countyService = new CountyService();
  const authService = new AuthService();

  const user = authService.getUserData();

  const columns = useMemo(
    () => [
      {
        Header: "Nimi",
        accessor: "full_name",
      },
      {
        Header: "Isikukood",
        accessor: "personal_id",
      },
      {
        Header: "Maakond",
        accessor: "local_government.county.name",
      },
      {
        Header: "Kohalik omavalitsus",
        accessor: "local_government.name",
      },
      {
        Header: 'Tegevuste algus',
        accessor: 'beginning_of_activities',
      },
      {
        Header: 'Tegevuse lõpp', 
        accessor: 'end_of_activities',
      },
      {
        Header: "Muuda",
        accessor: "id",
      },

    ],
    []
  );

  const fetchClients = () => {
    clientService
      .getAll({})
      .then((res) => {
        setClients(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchCounties = () => {
    countyService
      .getAll()
      .then((res) => {
        setCounties(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCounties();
  }, []);

  const filterClients = () => {
    setFilteredClients([]);
    clients
      .filter((client) => client.local_government.county_id === countyFilter)
      .map((client) => {
        setFilteredClients((prevState) => [...prevState, client]);
      });
  };

  useEffect(() => {
    filterClients();
  }, [countyFilter]);

  return (
    <div className="container py-5">
      <h2>Abivajajad</h2>

      <hr />
      {roleCheck(user.roles, ["super_admin", "admin", "coordinator"]) && (
        <div className="justify-between flex flex-wrap py-2 my-2">
          <Button cta="Lisa abivajaja" href="/clients/add" />
        </div>
      )}

      <hr />
      {roleCheck(user.roles, ["super_admin", "admin"]) && (
        <div className="counties flex space-x-2 py-2">
          {counties.map((county) => {
            if (
              clients.some(
                (client) => client.local_government.county_id === county.id
              )
            ) {
              return (
                <button
                  key={county.id}
                  className="p-2 bg-green-400 hover:bg-green-300 rounded"
                  onClick={() => setCountyFilter(county.id)}
                >
                  {county.name}
                </button>
              );
            }
            return;
          })}
          <button
            className="p-2 bg-yellow-400 hover:bg-yellow-300 rounded"
            onClick={() => setCountyFilter(null)}
          >
            Tühista filtrid
          </button>
        </div>
      )}

      <hr className="py-5" />
      <h3 className="mb-2">Tabelis kasutajaid: {filteredClients.length ? filteredClients.length : clients.length}</h3>

      <Table
        mode="clients"
        columns={columns}
        data={filteredClients.length ? filteredClients : clients}
      />
    </div>
  );
};

export default Clients;
