import CustomRouting from "./CustomRouting";
import { NotificationContextProvider } from "./context/errorContext";
import { AuthProvider } from "./context/AuthContext";

function App() {
  return (
    <AuthProvider>
      <NotificationContextProvider>
        <CustomRouting />
      </NotificationContextProvider>
    </AuthProvider>
  );
}

export default App;
