import ApiService from "./ApiService";

export default class PasswordService extends ApiService {
    collection = 'password';

    forgotPassword = async (email) => {
        return await this.client.post(`/api/${this.collection}/forgot-password`, {email});
    }

    resetPassword = async (params) => {
        return await this.client.post(`/api/${this.collection}/reset-password`, params);
    }
}