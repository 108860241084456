import { queryBuilder } from "./utils";
// eslint-disable-next-line
import { AxiosInstance } from "axios";
import JwtService from "./JwtService";

export default class ApiService extends JwtService {
  /**
   * @type {string}
   */
  collection = "";
  /**
   * @type {AxiosInstance}
   */
  client;

  /**
   * @type {{}}
   */
  globalFilters = {};

  /**
   * @type {[]}
   */
  globalSorts = [];

  /**
   * @type {[]}
   */
  globalIncludes = [];

  /**
   * @type {[]}
   */
  globalAppends = [];

  /**
   * @type {{}}
   */
  globalFields = {};

  constructor() {
    super();
  }

  /**
   * @param QBConfig {{}}
   * @returns {Promise<AxiosResponse<any>>}
   */
  getAll = async (QBConfig = {}) => {
    this.checkCollection();

    let url = `/api/${this.collection}`;
    QBConfig = this.getQBResult(QBConfig);
    url += `?${queryBuilder(QBConfig)}`;

    return await this.client.get(url);
  };

  /**
   * @param id
   * @param QBConfig
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSingle = async (id, QBConfig = {}) => {
    this.checkCollection();

    let url = `/api/${this.collection}/${id}`;
    QBConfig = this.getQBResult(QBConfig);
    url += `?${queryBuilder(QBConfig)}`;

    return await this.client.get(url);
  };

  /**
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  create = async (data) => {
    this.checkCollection();

    return this.client.post(`/api/${this.collection}`, data);
  };

  /**
   * @param id
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  update = async (id, data) => {
    this.checkCollection();
    return await this.client.put(`/api/${this.collection}/${id}`, data);
  };

  /**
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  destroy = async (id) => {
    this.checkCollection();

    return await this.client.delete(`/api/${this.collection}/${id}`);
  };

  getQBResult = (QBConfig) => {
    QBConfig.filters = { ...(QBConfig.filters || {}), ...this.globalFilters };
    QBConfig.sorts = [...(QBConfig.sorts || []), ...this.globalSorts];
    QBConfig.includes = [...(QBConfig.includes || []), ...this.globalIncludes];
    QBConfig.appends = [...(QBConfig.appends || []), ...this.globalAppends];
    QBConfig.fields = { ...(QBConfig.fields || {}), ...this.globalFields };
    return QBConfig;
  };

  checkCollection = () => {
    if (this.collection.trim().length < 1)
      throw new Error("Should specify collection name!");
  };
}
