const Input = ({
  label,
  id,
  type = "text",
  half = true,
  disabled,
  register,
  required,
  error,
  value = null,
  errorMessage,
  ...rest
}) => {
  let additionalProps = {};
  if (type === "number") additionalProps.step = "any";
  return (
    <>
      <label
        htmlFor={id}
        className={`block text-sm font-medium text-gray-700 ${
          disabled && "opacity-50"
        }`}
      >
        {label}
      </label>
      <input
        {...rest}
        type={type}
        name={id}
        id={id}
        autoComplete="given-name"
        disabled={disabled}
        {...register(id, { required, message: errorMessage })}
        className={`mt-1 ${
          error[id] && "border-red-500"
        } focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
          half && "w-1/2"
        }`}
        {...additionalProps}
      />
      {error[id] && <small className="text-red-500">{errorMessage}</small>}
    </>
  );
};

export default Input;
