import { Route, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import AuthService from "../../services/AuthService";

const PrivateAuth = ({ children, ...rest }) => {
    const authService = new AuthService();
    const authenticated = authService.isAuthenticated();
    const user = authService.getUserData();

  if (authenticated && user.roles.length === 0)
    return (
      <div className="container text-center py-10">
        <h2>Viga kasutaja rollis, palun võta ühendust administraatoriga</h2>
      </div>
    );
  if (authenticated === true) return <Route {...rest}>{children}</Route>;
  if (authenticated === false)
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  if (authenticated === null)
    return (
      <div className="container text-center py-10">
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    );

  return;
};

export default PrivateAuth;
